<template>
  <div class="flat-details ">
    <div class="item first">
      <span class="name-title">{{ $fn.tr('Apartement N') }} {{ transformedFlat.flat_number }}</span>
      <span class="price"> {{ transformedFlat.type.title_2 }}</span>
    </div>
    <price-changer :flat="transformedFlat"/>
    <div class="option-container">
      <div :class="{ active: activeTabId === 'Blueprint' }" @click="setActiveTab('Blueprint')" class="option-item">
        <span class="title">
          {{ $fn.tr('Blueprint') }}
        </span>
      </div>
      <div class="option-item" v-if="transformedFlat.image3d" :class="{ active: activeTabId === '3D' }"
           @click="setActiveTab('3D')">
        <span class="title">{{ $fn.tr('3D') }}</span>
      </div>
      <div @click="showCalculator = true" class="option-item">
        <svg width="51" height="40" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.5" y="1.5" width="23" height="15.0645" stroke="black" stroke-width="3"/>
          <rect x="1.5" y="23.4354" width="23" height="15.0645" stroke="black" stroke-width="3"/>
          <rect x="30.5" y="1.5" width="19" height="37" stroke="black" stroke-width="3"/>
        </svg>
        <span class="title">{{ $fn.tr('Calculator') }}</span>
      </div>
      <div @click="generateReport" class="option-item">
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M3.7418 49.9998C2.85641 49.9998 1.97193 49.752 1.21358 49.133C0.202086 48.3906 -0.177112 47.4004 0.075981 46.2868C0.708274 42.6981 8.16702 38.1183 14.7412 35.0243C15.8788 33.044 16.8903 30.9402 18.028 28.5886C19.5448 25.2468 20.8093 22.0293 21.8209 18.9352C19.4186 12.4995 18.6603 5.32115 20.3041 2.10382C21.1895 0.247764 22.5792 0 23.3384 0C24.476 0 25.3614 0.494665 25.9938 1.48486C27.8897 4.45533 26.7522 11.757 24.477 18.9353C25.4885 21.4103 26.7521 23.6384 28.2699 25.4946C29.6606 27.1038 31.3042 28.5886 32.9473 29.8267C40.2798 28.713 47.4854 28.713 50.0148 31.1881C50.9002 32.0548 51.2794 33.2919 50.7732 34.6534C50.394 35.767 49.5086 37.1284 47.2333 37.4997C43.4404 38.2421 37.3724 36.0148 32.1888 32.4253C29.0283 32.9199 25.6146 33.7867 22.2019 34.7769C20.432 35.3959 18.5361 36.1383 16.5131 37.0042C13.3526 42.6976 9.05441 48.8856 4.88224 49.8748C4.5004 49.8757 4.12121 50 3.74201 50L3.7418 49.9998ZM12.339 38.9851C7.15543 41.7078 2.8573 44.9254 2.47803 46.658C2.47803 46.7815 2.47803 46.9058 2.73112 47.1527C3.23643 47.5239 3.61651 47.5239 4.24792 47.4005C6.39699 46.9058 9.1784 43.8118 12.339 38.9852L12.339 38.9851ZM35.98 31.807C40.026 34.1586 44.3241 35.3956 46.5993 34.901C47.7369 34.6532 48.1161 34.0343 48.243 33.663C48.3691 33.1684 48.243 32.9206 48.1169 32.7963C46.7262 31.5592 42.048 31.188 35.9799 31.807L35.98 31.807ZM23.0847 22.4007C22.1993 25.0002 21.1887 27.4751 20.1772 29.5788C19.6719 30.569 19.0396 31.9305 18.2812 33.4152C19.4188 32.9205 20.4303 32.5485 21.3156 32.3016C23.7178 31.5591 26.4991 30.8167 29.4066 30.3212C28.142 29.331 27.0044 28.2174 25.993 26.9794C24.9815 25.6189 23.97 24.1332 23.0846 22.4005L23.0847 22.4007ZM23.0847 2.4753C22.9586 2.4753 22.7055 2.4753 22.3263 3.21773C21.3148 5.1981 21.5679 9.77703 22.8316 14.6037C24.3484 8.78695 24.6014 4.33157 23.59 2.84667C23.4639 2.59977 23.3378 2.47545 23.0847 2.47545V2.4753Z"
              fill="black"/>
        </svg>
        <span class="title">{{ $fn.tr('Generate PDF') }}</span>
      </div>
    </div>
    <h2 class="header-title">{{ $fn.tr('Details') }}</h2>
    <div class="description" v-for="item in transformedFlat.type.roomAreas">{{
        $fn.tr(item.tipText)
      }}: {{ item.tipHover }}
      <span class="m">{{ $fn.tr('m') }}<sup>2</sup></span>
    </div>
    <vue3-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
                   :filename="pdfName" :pdf-quality="2" pdf-format="a4" pdf-orientation="portrait"
                   :manual-pagination="true" ref="html2Pdf">
      <template v-slot:pdf-content>
        <FlatPdf/>
        <!-- <FlatPdf2 /> -->
      </template>
    </vue3-html2pdf>
    <div class="option-container mobile">
      <div @click="showCalculator = true" class="option-item">
        <svg width="51" height="40" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.5" y="1.5" width="23" height="15.0645" stroke="black" stroke-width="3"/>
          <rect x="1.5" y="23.4354" width="23" height="15.0645" stroke="black" stroke-width="3"/>
          <rect x="30.5" y="1.5" width="19" height="37" stroke="black" stroke-width="3"/>
        </svg>
        <span class="title">{{ $fn.tr('Calculator') }}</span>
      </div>
      <div @click="generateReport" class="option-item">
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M3.7418 49.9998C2.85641 49.9998 1.97193 49.752 1.21358 49.133C0.202086 48.3906 -0.177112 47.4004 0.075981 46.2868C0.708274 42.6981 8.16702 38.1183 14.7412 35.0243C15.8788 33.044 16.8903 30.9402 18.028 28.5886C19.5448 25.2468 20.8093 22.0293 21.8209 18.9352C19.4186 12.4995 18.6603 5.32115 20.3041 2.10382C21.1895 0.247764 22.5792 0 23.3384 0C24.476 0 25.3614 0.494665 25.9938 1.48486C27.8897 4.45533 26.7522 11.757 24.477 18.9353C25.4885 21.4103 26.7521 23.6384 28.2699 25.4946C29.6606 27.1038 31.3042 28.5886 32.9473 29.8267C40.2798 28.713 47.4854 28.713 50.0148 31.1881C50.9002 32.0548 51.2794 33.2919 50.7732 34.6534C50.394 35.767 49.5086 37.1284 47.2333 37.4997C43.4404 38.2421 37.3724 36.0148 32.1888 32.4253C29.0283 32.9199 25.6146 33.7867 22.2019 34.7769C20.432 35.3959 18.5361 36.1383 16.5131 37.0042C13.3526 42.6976 9.05441 48.8856 4.88224 49.8748C4.5004 49.8757 4.12121 50 3.74201 50L3.7418 49.9998ZM12.339 38.9851C7.15543 41.7078 2.8573 44.9254 2.47803 46.658C2.47803 46.7815 2.47803 46.9058 2.73112 47.1527C3.23643 47.5239 3.61651 47.5239 4.24792 47.4005C6.39699 46.9058 9.1784 43.8118 12.339 38.9852L12.339 38.9851ZM35.98 31.807C40.026 34.1586 44.3241 35.3956 46.5993 34.901C47.7369 34.6532 48.1161 34.0343 48.243 33.663C48.3691 33.1684 48.243 32.9206 48.1169 32.7963C46.7262 31.5592 42.048 31.188 35.9799 31.807L35.98 31.807ZM23.0847 22.4007C22.1993 25.0002 21.1887 27.4751 20.1772 29.5788C19.6719 30.569 19.0396 31.9305 18.2812 33.4152C19.4188 32.9205 20.4303 32.5485 21.3156 32.3016C23.7178 31.5591 26.4991 30.8167 29.4066 30.3212C28.142 29.331 27.0044 28.2174 25.993 26.9794C24.9815 25.6189 23.97 24.1332 23.0846 22.4005L23.0847 22.4007ZM23.0847 2.4753C22.9586 2.4753 22.7055 2.4753 22.3263 3.21773C21.3148 5.1981 21.5679 9.77703 22.8316 14.6037C24.3484 8.78695 24.6014 4.33157 23.59 2.84667C23.4639 2.59977 23.3378 2.47545 23.0847 2.47545V2.4753Z"
              fill="black"/>
        </svg>
        <span class="title">{{ $fn.tr('Generate PDF') }}</span>
      </div>
    </div>

  </div>
  <teleport to="body">
    <calculator-pop-up @close="showCalculator = false" :transformedFlat="transformedFlat" v-if="showCalculator"/>
  </teleport>
</template>

<script setup>
import { computed, inject, ref, defineProps } from "vue";
import { useProjects } from "@/store/pinia/projects";
import Vue3Html2pdf from "vue3-html2pdf";
import FlatPdf from '../flats/FlatPdf';
import FlatPdf2 from '../flats/FlatPdf2';
import CalculatorPopUp from "@/components/flats/CalculatorPopUp";
import { useMenuStore } from "../../store/pinia/menu";
import PriceChanger from '@/components/flats/PriceChanger';

let props = defineProps({
  activeTabId: [String]
});
const { flat } = inject('flatData');
const emit = defineEmits(['change']);
let projectStore = useProjects();
let showCalculator = ref(false);
const types = computed(() => projectStore._types);
const html2Pdf = ref(null);
const pdfName = computed(() => `apartment-${ flat.value?.flat_number }`);
let transformedFlat = computed(() => {
  let flatData = flat.value || {};
  let type = types.value?.find(type => type.id === flatData?.type_id) || {};

  return {
    ...flatData,
    type: type || {},
    ...type
  };
});
const sliderOptions = computed(() => {
  return {
    width: '80%',
    railStyle: {
      backgroundColor: '#000000',
      height: '1px',
    },
    processStyle: {
      backgroundColor: '#000000',
      height: '1px',
    },
    tooltipStyle: {
      display: 'none',
    },
  };
});
const setActiveTab = (val) => {
  emit('change', val);
};

const generateReport = () => {
  html2Pdf.value.generatePdf();
};

</script>

<style lang="scss" scoped>
.flat-details {
  margin: 70px 0;
  padding-left: 87px;
  border-left: 1px solid #000000;

  @media only screen and (max-width: 1439px) and (min-width: 1024px) {
  }

  @media only screen and (max-width: 1023px) {
    border-left: unset;
    padding-left: unset;
    padding: 0 21px;
  }

  @media only screen and (max-width: 767px) {
    display: block;
    margin-top: 10px;
  }

  .header-title {
    font-weight: 400;
    font-size: 34px;
    color: #9C9C9C;
    margin: 21px 0;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      font-size: 21px;
    }

    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      font-size: 14px;
      margin-bottom: 21px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 21px;
    }
  }

  .option-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
    align-self: flex-end;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    .option-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      padding: 10px 21px;
      cursor: pointer;
      height: 45px;
      transition: all .45s ease;
      margin-right: 5px;
      margin-bottom: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:hover,
      &.active {
        background: #000000;

        .title {
          color: white;
        }

        path,
        rect {
          fill: white;
        }
      }

      svg {
        width: 18px;
        margin-right: 10px;
      }

      path,
      rect,
      .title {
        transition: all .45s ease;
      }

      .title {
        font-weight: 400;
        font-size: 21px;

        @media only screen and (max-width: 1279px) {
          font-size: 16px;
        }
      }
    }
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    display: block;
    line-height: unset;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      font-size: 27px;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }

  }

  .description {
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
  }

  .item {
    display: flex;
    align-items: center;

    &.first {
      flex-direction: column;
      align-items: flex-start;

      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    .name-title {
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 34px;

      @media only screen and (max-width: 1650px) {
        font-size: 21px;
      }
    }


    .price {
      font-weight: 400;
      font-size: 21px;

      @media only screen and (max-width: 1650px) {
        font-size: 16px;
      }
    }
  }

  .price-changer {
    margin-top: 15px;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .option-container.mobile {
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
      margin-top: 34px;

      .option-item {
        border: 1px solid #000000;
        background: transparent;
        height: 60px;
      }
    }

  }
}</style>

