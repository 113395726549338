<template>
  <div class="price-changer">
    <div class="item second">
      <div class="currency-picker-wrapper">
        <div class="currency-picker">
          <div @click="toggleDropdown" class="header">
            <span class="title">{{ activeCurrencyTitle }}</span>
            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.4607 13.603L0.632428 0.0440688L16.289 0.0440701L8.4607 13.603Z" fill="black"/>
            </svg>
          </div>
          <div :class="{active:showDropdown}" class="drop-down">
            <span v-for="item in filteredCurrency"
                  @click="setCurrency(item.id)"
                  class="title">{{ item.title }}</span>
          </div>
        </div>
        <span class="title">   {{ flatPrice }}</span>
      </div>
      | <span class="title">{{ Math.floor(flat.type.area_m2) }} m<sup>2</sup></span>
    </div>
    <span class="price">{{ $fn.tr('Price') }}  M<sup>2</sup> {{ activeCurrencyTitle }}  {{ m2Price }}</span>
  </div>
</template>

<script setup>
import { useMenuStore } from "../../store/pinia/menu";
import { computed, ref } from "vue";

let props = defineProps({
  flat: Object
});

let {
  activeCurrencyTitle,
  filteredCurrency,
  setCurrency,
  showDropdown,
  toggleDropdown,
  flatPrice, m2Price
} = useCurrency();

function moneyFormat(n) {
  return (Math.round(n * 100) / 100).toLocaleString();
}

function useCurrency() {
  let menustore = useMenuStore();
  let usd = menustore.indx?.usd?.usd;
  let currencyList = [
    {
      id: 'USD',
      title: '$'
    },
    {
      id: 'Gel',
      title: '₾'
    },
  ];
  let activeCurrency = ref('USD');
  let showDropdown = ref(false);
  let filteredCurrency = computed(() => currencyList?.filter(item => item.id !== activeCurrency.value));
  let activeCurrencyTitle = computed(() => currencyList?.find(item => item.id === activeCurrency.value)?.title);
  let flatPrice = computed(() => {
    let newPrice = Math.floor(props.flat.price_m2 * props.flat.development_types_area_m2);
    return activeCurrency.value === "USD" ? moneyFormat(newPrice) : moneyFormat(Math.floor(newPrice * usd));
  });
  let m2Price = computed(() => activeCurrency.value === "USD" ? moneyFormat(Math.floor(props.flat.price_m2)) : moneyFormat(Math.floor(props.flat.price_m2 * usd)));

  const setCurrency = (val) => {
    activeCurrency.value = val;
    toggleDropdown();
  };
  const toggleDropdown = () => {
    showDropdown.value = !showDropdown.value;
  };

  return {
    activeCurrencyTitle,
    filteredCurrency, setCurrency,
    showDropdown, toggleDropdown,
    flatPrice, m2Price
  };
}
</script>


<style lang="scss" scoped>
.second {
  display: flex;
  align-items: center;
  grid-gap: 15px;

  .title {
    position: relative;
    font-weight: 400;
    font-size: 55px;
    line-height: 65px;
    @media only screen and (max-width: 1650px) and (min-width: 1280px) {
      font-size: 34px;
      line-height: 45px;
    }
    @media only screen and (max-width: 1279px) {
      font-size: 21px;
    }

  }

  sup {
    position: absolute;
    top: -5px;
  }


}

.price {
  font-weight: 400;
  font-size: 21px;
  @media only screen and (max-width: 1650px) {
    font-size: 16px;
  }
}

.currency-picker-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;

  .currency-picker {
    position: relative;
    background: #F3F3F3;
    border: 2px solid #A6A6A6;
    width: 74px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and(max-width: 1279px) {
      width: 54px;
      height: 54px;
    }

    .header {
      display: flex;
      align-items: center;
      cursor: pointer;
      grid-gap: 5px;

      .title {
        line-height: unset;
      }
    }

    .drop-down {
      position: absolute;
      opacity: 0;
      bottom: 0;
      transform: translateY(100%);
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #A6A6A6;
      width: 74px;
      height: 74px;
      transition: all .45s ease-out;
      @media screen and(max-width: 1279px) {
        width: 54px;
        height: 54px;
      }

      &.active {
        opacity: 1;
      }

      .title {
        cursor: pointer;
        line-height: unset;
      }
    }
  }
}
</style>