<template>
  <div class="flat-inner">
    <div class="flat-inner__header-container">
      <div class="left">
        <svg width="52" height="54" class="back-icon" @click="goBack" viewBox="0 0 52 54" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.3291 24.5L51.5 24.5L51.5 29.5L9.8325 29.5L30.5687 50.2362L27.0332 53.7717L2.2775 29.0161L0.509729 27.2483L2.2775 25.4805L27.0332 0.724837L30.5687 4.26037L10.3291 24.5Z"
                fill="black"/>
        </svg>
        <div class="item">
          <svg width="25" height="41" viewBox="0 0 25 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 0V31.5545L8.92857 41V9.50566L18.9078 3.64469H4.46429V32.8002H0V0.000244069L25 0Z"
                  fill="black"/>
          </svg>
          <span class="title">{{ transformedFlat.block.title }}</span>
        </div>
        <div class="item">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16V8H24V0H32V32H0V24H8V16H16Z" fill="black"/>
          </svg>
          <span class="title"> {{
              $fn.tr('Floor')
            }} {{ transformedFlat.floor.floor_number === 0 ? 'L' : transformedFlat.floor.floor_number }}</span>
        </div>
        <div class="item mobile">
          <span class="name-title">{{ $fn.tr('Apartement N') }} {{ transformedFlat.flat_number }}</span>
          <span class="price"> {{ transformedFlat.type.title_2 }}</span>
        </div>
      </div>
      <div class="right">
        <a :href="`${socialMedia.url}${getFullUrl}`" onclick="window.open(this.href,'targetWindow',
                                       `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=800`);
     return false;">
          <div class="item">
            <div class="box">
              <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.0133 12.1665H3.32538V21.6849C3.32538 21.8729 3.47765 22.0252 3.66558 22.0252H7.5858C7.77373 22.0252 7.926 21.8729 7.926 21.6849V12.2113H10.5839C10.7568 12.2113 10.9022 12.0817 10.9219 11.91L11.3256 8.40577C11.3367 8.30936 11.3061 8.21281 11.2416 8.14048C11.177 8.06809 11.0846 8.02665 10.9877 8.02665H7.92614V5.83003C7.92614 5.16786 8.28267 4.83208 8.98594 4.83208C9.08616 4.83208 10.9877 4.83208 10.9877 4.83208C11.1756 4.83208 11.3279 4.67974 11.3279 4.49188V1.27534C11.3279 1.08741 11.1756 0.935135 10.9877 0.935135H8.22899C8.20953 0.934182 8.16632 0.932617 8.10264 0.932617C7.62397 0.932617 5.96018 1.02658 4.64591 2.23566C3.18971 3.57551 3.39213 5.17977 3.4405 5.45792V8.02658H1.0133C0.82537 8.02658 0.673096 8.17886 0.673096 8.36678V11.8262C0.673096 12.0142 0.82537 12.1665 1.0133 12.1665Z"
                    fill="black"/>
              </svg>

            </div>
            <span class="title">{{ $fn.tr('share') }}</span>
          </div>
        </a>
        <div class="item" :class="favoritesStore.favoriteFlatIds.includes(transformedFlat.id) ? 'active' : ''"
             @click="addToFavorites(transformedFlat.id)">
          <div class="box">
            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10.2631 15.6142L10.1631 15.7142L10.0531 15.6142C5.30309 11.3042 2.16309 8.45421 2.16309 5.56421C2.16309 3.56421 3.66309 2.06421 5.66309 2.06421C7.20309 2.06421 8.70309 3.06421 9.23309 4.42421H11.0931C11.6231 3.06421 13.1231 2.06421 14.6631 2.06421C16.6631 2.06421 18.1631 3.56421 18.1631 5.56421C18.1631 8.45421 15.0231 11.3042 10.2631 15.6142ZM14.6631 0.064209C12.9231 0.064209 11.2531 0.874209 10.1631 2.14421C9.07309 0.874209 7.40309 0.064209 5.66309 0.064209C2.58309 0.064209 0.163086 2.47421 0.163086 5.56421C0.163086 9.33421 3.56309 12.4242 8.71309 17.0942L10.1631 18.4142L11.6131 17.0942C16.7631 12.4242 20.1631 9.33421 20.1631 5.56421C20.1631 2.47421 17.7431 0.064209 14.6631 0.064209Z"
                  fill="black"/>
            </svg>
          </div>
          <span class="title">{{ $fn.tr('favorite_save') }}</span>
        </div>
      </div>
    </div>
    <div class="mobile-flat-about">
      <price-changer :flat="transformedFlat"/>
    </div>
    <flats-main :flats="transformedFlat"/>
    <div v-if="transformedFlat.viewImg || completeActiveTab.img" class="down-tabs-container">
      <div :class="{ white: activeTabId !== 'Blueprint' }" class="tabs-list flex j-center ">
        <template v-for="tab in tabs">
          <div v-if="tab.label" :key="tab.title" :class="{ active: activeTabId === tab.title }" class="tab-item pointer"
               @click="setActiveTab(tab.title)">
            <span> {{ tab.label }}</span>
          </div>
        </template>
      </div>
      <div :class="{ active: activeTabId === 'Completed' }" class="completed-section tab-content">
        <span class="tab-content-header-title">{{ activeTab.tempTitle }}</span>
        <img :src='completeActiveTab.img' class="image" alt="">
      </div>
      <template v-if="activeTabId === 'Completed'">
        <div class="completed-tabs-list">
          <p v-for="tab in completedTabs" :key="tab.title" :class="{ active: completeActiveTabId === tab.title }"
             class="tab-item pointer" @click="setCompleteActiveTab(tab.title)">
            {{ tab.label }}
          </p>
        </div>
        <div class="pin" v-for="pin in completeActiveTab.pins" :style="{ ...pin.positions }">
          <div class="pin-pop-over">
            <img src="../../../assets/imgs/subtract.png" class="background" alt="">
            <div class="content">
              <span>Complete</span>
              <img :src="pin.img" alt="">
              <div class="pin__label">{{ $fn.tr(pin.data.title) }}</div>
            </div>
          </div>
          <div class="pin-icon-container">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="13" width="4" height="31" fill="black"/>
              <rect x="31" y="14" width="4" height="31" transform="rotate(90 31 14)" fill="black"/>
            </svg>
          </div>
        </div>
      </template>
      <div :class="{ active: activeTabId === 'ViewFrom' }" class="view-section tab-content">
        <span class="tab-content-header-title">{{ activeTab.tempTitle }}</span>
        <img :src="transformedFlat.viewImg" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import get from 'lodash/get';
import { computed, onMounted, provide, ref } from "vue";
import { useMenuStore } from "@/store/pinia/menu";
import func from "../../../helpers/func";
import { useProjects } from "@/store/pinia/projects";
import { UseFavoriteFlats } from '@/store/pinia/FavoriteProjects';
import { useRoute, useRouter } from "vue-router";
import { minMax } from '@/composables/minMax';
import fn from "@/helpers/func";
import FlatsMain from "../../flats/FlatsMain";
import PriceChanger from '@/components/flats/PriceChanger';

let activeTabId = ref('Completed');
let completeActiveTabId = ref('Basic');
let store = useMenuStore();
let favoritesStore = UseFavoriteFlats();
let route = useRoute();
let router = useRouter();
let projectStore = useProjects();

let addToFavorites = (id) => {
  favoritesStore.addFavorite(id);
};

let setActiveTab = (tab) => {
  activeTabId.value = tab;
};
let setCompleteActiveTab = (tab) => {
  completeActiveTabId.value = tab;
};
let activeTab = computed(() => tabs.value.find(item => item.title === activeTabId.value) || {});
let completeActiveTab = computed(() => completedTabs.value?.find(item => item.title === completeActiveTabId.value) || {});
const getUSD = computed(() => {
  return parseFloat(get(store, "indx.usd.usd"));
});
let activeCurrency = ref('USD');

const tabs = computed(() => {
  return [
    {
      title: 'Completed',
      tempTitle: fn.tr('Completed'),
      label: activeType.value?.pintitle,
      img: require('@/assets/imgs/inside.png')
    },
    {
      title: 'ViewFrom',
      tempTitle: fn.tr('ViewFrom'),
      label: activeType.value?.['view_title'],
    },
  ];
});
const activeType = computed(() => {
  let flatData = flat.value || {};

  return types.value?.find(type => type.id === flatData?.type_id) || {};
});
const sliderOptions = {
  max: 2000,
  min: 0,
  width: '80%',
  railStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  processStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  tooltipStyle: {
    display: 'none',
  },
};
let calculatorRangeValue = ref(1);
let activeProjectSlug = route.params?.path2;
let flat = ref(null);
const appID = computed(() => {
  return get(store, "selected_menu.routeParams.apview");
});
let projects = computed(() => projectStore._projects);
let floors = computed(() => projectStore._floors);
const blocks = computed(() => projectStore._blocks);
const types = computed(() => projectStore._types);

const socialMedia = computed(() => store.indx?.widgets?.share_links?.facebook || {});
const getFullUrl = computed(() => {
  return window.location.href;
});

let transformedFlat = computed(() => {
  let flatData = flat.value || {};
  let type = activeType.value;
  let flatPrice = Math.floor(flatData.price_m2 * flatData.development_types_area_m2);

  return {
    ...flatData,
    block: blocks.value?.find(block => block.id === flatData.block_id) || {},
    floor: floors.value?.find(floor => floor.block_id === flatData.block_id && floor.floor_number === flatData.floor_number) || {},
    type: type,
    bluePrintImg: type?.image2d?.[0]?.devices?.desktop,
    image3d: type?.image3d?.[0]?.devices?.desktop,
    viewImg: type?.imageview?.[0]?.devices?.desktop,
    price: activeCurrency.value === 'USD' ? flatPrice : Math.floor(flatPrice * getUSD.value),
  };
});
const completedTabs = computed(() => transformedFlat.value.type?.render?.map((item, index) => {
  if (index === 0) completeActiveTabId.value = item.title;
  return {
    ...item,
    label: item.title,
    img: item?.attachedImages?.[0]?.devices?.desktop,
    pins: item?.pins?.map(pin => {
      return {
        positions: { left: pin.x + '%', top: pin.y + '%' },
        data: pin.data[store.getLang] || {},
        img: pin.images?.[0]?.devices?.desktop
      };
    })
  };
}));
const cursorClick = (direction) => {
  let activeTabs = tabs.value.filter(item => item.label);

  let currentTabIndex = activeTabs?.findIndex(tab => tab.title === activeTabId.value);
  let nextItem = activeTabs[minMax(currentTabIndex + direction, 0, activeTabs.length - 1)];

  if (!nextItem?.label) return;

  activeTabId.value = nextItem?.title;
};
const getFlat = async () => {
  const ids = {
    id: [appID.value],
  };
  let { data } = await func.postData("dev/getFlats", ids);

  flat.value = data.list?.[0];

  setTimeout(() => {
    setActiveTab(tabs.value?.filter(item => item.label)?.[0]?.title);
  }, 500);
};
const getData = async () => {
  await projectStore.getProjects(activeProjectSlug);
  projectStore.getTypes();
  projectStore.getBlocks();
  projectStore.getFloors();
  getFlat();
};
const goBack = () => {
  let path = route?.path?.split('/');
  path[path.length - 1] === '' ? path.splice(-2) : path?.splice(-1);

  router.push({ path: path?.join('/') });
};

provide('flatData', {
  flat,
  transformedFlat
});
getData();
onMounted(async () => {
  setTimeout(() => {
    store.scrollToTopLoco = !store.scrollToTopLoco;
  }, 100);
});
</script>

<style lang="scss" scoped>
.flat-inner {
  width: 100%;

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    display: block;
    line-height: unset;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      font-size: 27px;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 24px;
    }
  }

  .number {
    font-weight: 400;
    font-size: 64px;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      font-size: 40px;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      font-size: 40px;
    }

    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 24px !important;
    }

    span {
      color: #9C9C9C;
      transition: all .45s ease;
      cursor: pointer;

      &.active {
        color: #000000
      }
    }
  }

  &__header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 61px 48px;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      padding: 0 45px 48px;
    }

    @media only screen and (max-width: 1439px) and (min-width: 768px) {
      padding: 0 21px 48px;
    }

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }

    .left {
      display: flex;
      align-items: center;

      .back-icon {
        @media only screen and (max-width: 1440px) {
          width: 30px;
        }
      }

      .item {
        display: flex;
        align-items: center;
        padding-right: 50px;
        position: relative;
        margin-left: 40px;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
          padding-right: 30px;
          margin-left: 20px;
        }

        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
          padding-right: 21px;
          margin-left: 21px;
        }

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          padding-right: 14px;
          margin-left: 14px;
        }

        @media only screen and (max-width: 767px) {
          display: none;
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(2) {
          &:after {
            content: '';
            width: 1px;
            height: 120px;
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media only screen and (max-width: 1023px) and (min-width: 768px) {
              height: 48px;
            }

            @media only screen and (max-width: 767px) {
              display: none;
            }

          }
        }

        svg {
          @media only screen and (max-width: 1650px) {
            width: 18px;
          }
        }

        .label {
          @media only screen and (max-width: 1023px) and (min-width: 768px) {
            font-size: 12px;
          }
        }

        .title {
          font-weight: 400;
          font-size: 54px;
          line-height: 64px;
          margin-left: 8px;

          @media only screen and (max-width: 1650px) {
            font-size: 34px;
            line-height: 45px;
          }
          @media only screen and (max-width: 1440px) {
            font-size: 21px;
            line-height: 35px;
          }
        }

        &:last-child {
          svg {
            @media only screen and (max-width: 1650px) {
              width: 25px;
            }
          }

          &:after {
            display: none;
          }
        }
      }

      .item.mobile {
        display: none;

        @media only screen and (max-width: 767px) {
          display: block;
          margin-left: 0;

          span {
            display: block;
          }

          .price {
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      .back-icon {
        cursor: pointer;

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          width: 30px;
        }

        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }


    .right {
      display: flex;
      align-items: center;
      cursor: pointer;

      @media only screen and (max-width: 500px) {
        display: block;
      }

      .item {
        display: flex;
        align-items: center;

        &:last-child {
          margin-left: 30px;

          @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            margin-left: 18px;
          }

          @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin-left: 12px;
          }

          @media only screen and (max-width: 500px) {
            margin-left: 0;
            margin-top: 10px;
          }
        }

        .title {
          @media only screen and (max-width: 767px) {
            font-size: 21px;
          }
        }

        .star {
          path {
            fill: transparent;
            stroke: black;
            stroke-width: 1px;
            transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
          }
        }

        &.active {
          .star {
            path {
              fill: black;
            }
          }
        }

        &:hover .box {
          //background: #000000;
        }

        &:hover path {
          //stroke: #fff;
        }

        .box {
          width: 56px;
          height: 56px;
          border: 2px solid #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);

          @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            width: 42px;
            height: 42px;
          }

          @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            width: 30px;
            height: 30px;
            margin-right: 13px;
          }

          @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 22px;
            height: 22px;
            margin-right: 9px;
          }

          @media only screen and (max-width: 767px) {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }

          svg {
            @media only screen and (max-width: 1650px) and (min-width: 768px) {
              width: 15px;
            }

            @media only screen and (max-width: 1023px) {
              width: 10px;
            }
          }

        }
      }
    }
  }

  .mobile-flat-about:deep() {
    padding: 21px;
    margin-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: none;

    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .item {
      .title {
        font-size: 28px !important;
      }
    }


  }

  .pin {
    position: absolute;
    cursor: pointer;
    z-index: 12;

    @media only screen and (max-width: 1023px) {
      display: none;
    }

    &__label {
      font-size: 21px;
      color: black;
    }

    svg {
      circle {
        transition: .3s;
      }
    }

    .pin-pop-over {
      pointer-events: none;
      position: absolute;
      top: 0;
      height: 400px;
      left: 50%;
      transform: translate(-50%, -100%);
      opacity: 0;
      transition: all .45s ease;
      padding: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .background {
        position: absolute;
        height: 100%;
      }

      .content {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          height: 164px;
          width: 164px;
          object-fit: cover;
          margin: 26px 0 16px;
        }
      }
    }

    .pin-icon-container {
      background: white;
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .45s ease;

      &:hover {
        opacity: .5;
      }
    }

    &:hover {
      z-index: 3;

      .pin-pop-over {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -110%);
      }

    }
  }

  .completed-tabs-list {
    position: absolute;
    left: 34px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 12;

    @media only screen and (max-width: 757px) {
      display: none;
    }

    .tab-item {
      color: white;
      font-weight: 400;
      font-size: 41px;
      margin-top: 30px;

      @media only screen and (max-width: 1023px) {
        font-size: 21px;
      }

      &:first-child {
        margin-top: 0;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }

  .down-tabs-container {
    min-width: 55%;
    height: 1200px;
    margin-top: 0;

    position: relative;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      height: 1100px;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      height: 700px;
    }

    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      height: 600px;
    }

    @media only screen and (max-width: 767px) {
      height: 400px;
    }

    .tabs-list {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 12;
      padding-bottom: 0;

      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        width: 60%;
      }

      @media only screen and (max-width: 767px) {
        bottom: 10px;
        width: 100%;
      }

      &.white {
        color: white !important;
      }

      .tab-item {
        padding: 10px;
        height: 46px;
        display: flex;
        align-self: center;
        justify-content: center;
        margin-right: 24px;
        line-height: unset;

        @media only screen and (max-width: 1023px) {
          font-size: 14px;
          height: unset;
        }

        @media only screen and (max-width: 767px) {
          margin-right: 12px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          background: white;
          color: black !important;
        }

      }
    }

    .tab-content {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      transition: all .45s ease;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      .tab-content-header-title {
        font-weight: 400;
        font-size: 51px;
        color: white;
        position: absolute;
        left: 33px;
        top: 50px;

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          font-size: 21px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 20px;
          left: 18px;
          top: 22px;
        }
      }
    }

    .completed-section {
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .view-section {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


}</style>
