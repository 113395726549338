<template>
  <div class="flat-main">
    <div class="tabs-container">
      <div class="option-container">
        <div :class="{active:activeTabId==='Blueprint'}"
             @click="setActiveTab('Blueprint')"
             class="option-item">
        <span class="title">
          {{ $fn.tr('2D') }}
        </span>
        </div>
        <div class="option-item"
             :class="{active:activeTabId==='3D'}"
             v-if="flats.image3d"
             @click="setActiveTab('3D')"
        >
          <span class="title">{{ $fn.tr('3D') }}</span>
        </div>
      </div>
      <div :class="{active:activeTabId==='Blueprint'}"
           class="blueprint-section tab-content">
        <span style="color: black" class="tab-content-header-title">{{ activeTab.tempTitle }}</span>
        <img :src="flats.bluePrintImg" class="image" alt="">
      </div>
      <div :class="{active:activeTabId==='3D'}"
           @click="setActiveTab('3D')"
           class="d3-section tab-content">
        <span class="tab-content-header-title">{{ activeTab.tempTitle }}</span>
        <img :src="flats.image3d" class="image" alt="">
      </div>
    </div>
    <flats-details @change="setActiveTab"
                   :activeTabId="activeTabId"/>
  </div>
</template>

<script setup>
import FlatsDetails from '@/components/flats/FlatsDetails'
import {computed, ref} from "vue";
import fn from "@/helpers/func"
import {useProjects} from "../../store/pinia/projects";

let props = defineProps({
  flats: Object
})

let {activeTabId, activeTab, setActiveTab} = useTabs()

function useTabs() {
  let projectStore = useProjects()
  const types = computed(() => projectStore._types);

  let activeTabId = ref('Blueprint');
  let setActiveTab = (tab) => {
    activeTabId.value = tab
  }
  const activeType = computed(() => {
    let flatData = props.flat || {}

    return types.value?.find(type => type.id === flatData?.type_id) || {}
  })
  const tabs = computed(() => {
    return [
      {
        title: 'Blueprint',
        tempTitle: fn.tr('Blueprint'),
        label: activeType.value?.['2d_title'],
        color: '#F1F1F1',
        img: require('@/assets/imgs/bluprint.png')
      },
      {
        title: '3D',
        tempTitle: fn.tr('3D'),
        label: activeType.value?.['3d_title'],
        color: 'black',
        img: require('@/assets/imgs/3d.png')

      },
    ]
  })

  let activeTab = computed(() => tabs.value.find(item => item.title === activeTabId.value) || {})

  return {activeTabId, activeTab, setActiveTab}
}
</script>

<style lang="scss" scoped>
.flat-main {
  display: flex;
  width: 100%;
  background: #F1F1F1;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }

  .tabs-container {
    min-width: 55%;
    height: 1200px;
    position: relative;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      height: 1100px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      height: 700px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      height: 600px;

    }
    @media only screen and (max-width: 1023px) {
      width: 100%;
    }
    @media only screen and (max-width: 767px) {
      height: 400px;
    }

    .tab-content {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0 21px;
      left: 0;
      top: 0;
      opacity: 0;
      transition: all .45s ease;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      .tab-content-header-title {
        font-weight: 400;
        font-size: 34px;
        display: block;
        position: relative;
        margin-top: 70px;
        margin-bottom: 20px;
        @media only screen and (max-width: 1279px) {
          font-size: 21px;
        }
        @media only screen and (max-width: 767px) {
          margin-top: 20px;
          margin-bottom: 0;
        }

      }
    }

    .blueprint-section {
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        margin-top: 34px;
      }
    }

    .d3-section {

      img {
        width: 100%;
      }
    }

    .option-container {
      position: absolute;
      left: 50%;
      width: max-content;
      transform: translateX(-50%);
      bottom: 0;
      display: none;
      align-items: center;
      margin-top: 30px;
      align-self: flex-end;
      flex-wrap: wrap;
      z-index: 12;
      @media only screen and (max-width: 767px) {
        display: flex;
      }

      .option-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        padding: 10px 21px;
        cursor: pointer;
        height: 45px;
        transition: all .45s ease;
        margin-right: 5px;
        margin-bottom: 10px;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        &:first-child {
          margin-left: 0;
        }

        &:hover, &.active {
          background: #000000;

          .title {
            color: white;
          }

          path, rect {
            fill: white;
          }
        }

        svg {
          width: 18px;
          margin-right: 10px;
        }

        path, rect, .title {
          transition: all .45s ease;
        }

        .title {
          font-weight: 400;
          font-size: 21px;
        }
      }
    }

  }

}
</style>