<template>
  <div class="calculator-pop-up">
    <div class="calculator-content">
      <svg @click="$emit('close')" width="22"
           height="22"
           class="close-icon"
           viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.41406" width="28" height="2" transform="rotate(45 1.41406 0)" fill="black"/>
        <rect x="0.414062" y="19.7988" width="28" height="2" transform="rotate(-45 0.414062 19.7988)" fill="black"/>
      </svg>
      <h2 class="title">{{ $fn.tr('Calculator') }}</h2>
      <div class="item">
        <span class="label">{{ $fn.tr('Price') }}</span>
        <div class="number">
          <div class="left">
              <span
                  @click="activeCurrency='USD'"
                  :class="{active:activeCurrency==='USD'}">$</span>
            {{ transformedFlatData.price }}
          </div>
          <span
              @click="activeCurrency='GEL'"
              :class="{active:activeCurrency==='GEL'}">₾</span>
        </div>
      </div>
      <div class="range-container">
        <span>{{ $fn.tr('Downpayment') }} % {{ complicity }} </span>
        <vue-slider v-model="complicity"
                    v-bind="sliderOptions">
          <template v-slot:dot>
            <svg
                class="custom-dot pointer"
                width="42"
                height="32"
                viewBox="0 0 42 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" y="0.726013" width="41" height="30" fill="white" stroke="black"/>
              <line x1="21.5" y1="6.22601" x2="21.5" y2="25.226" stroke="black"/>
            </svg>
          </template>
        </vue-slider>
      </div>
      <div class="range-container">
        <span>{{ $fn.tr('Percentage') }} % {{ percent }} </span>
        <vue-slider v-model="percent"
                    v-bind="sliderOptionsPercentage">
          <template v-slot:dot>
            <svg
                class="custom-dot pointer"
                width="42"
                height="32"
                viewBox="0 0 42 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" y="0.726013" width="41" height="30" fill="white" stroke="black"/>
              <line x1="21.5" y1="6.22601" x2="21.5" y2="25.226" stroke="black"/>
            </svg>
          </template>
        </vue-slider>
      </div>
      <div class="calculator-select">
        <div class="input">
          <input type="number" v-model="duration" :placeholder="$fn.tr('Loan Term')">
          <i></i>
        </div>
      </div>
      <div @click="calculate" class="button">{{ $fn.tr('Calculate') }}</div>
      <div class="text-contents">
        <span class="text">{{$fn.tr('Montly Payment')}}</span>
        <span class="header-title">$ {{ result }}</span>
        <!--        <span class="title">M2 Payment: $ 2 345</span>-->
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import VueSlider from 'vue-slider-component';
import get from "lodash/get";
import {useMenuStore} from "@/store/pinia/menu";

let props = defineProps({
  transformedFlat: Object
})
let store = useMenuStore()
let showSelectOptions = ref(false);
let selectedValue = ref('48 Month');
let activeCurrency = ref('USD')
let calculatorRangeValue = ref(0)
let percentageRangeValue = ref(0)
const sliderOptions = ref({
  max: 100,
  min: 0,
  width: '80%',
  interval: 1,
  railStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  processStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  tooltipStyle: {
    display: 'none',
  },
})
const sliderOptionsPercentage = ref({
  max: 100,
  min: 0,
  width: '80%',
  interval: 1,
  railStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  processStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  tooltipStyle: {
    display: 'none',
  },
})
const getUSD = computed(() => {
  return parseFloat(get(store, "indx.usd.usd"));
});
let transformedFlatData = computed(() => {
  let flatData = props.transformedFlat || {}
  let flatPrice = Math.floor(flatData.price_m2 * flatData.development_types_area_m2)

  return {
    ...flatData,
    price: activeCurrency.value === 'USD' ? flatPrice : Math.floor(flatPrice * getUSD.value),

  }
})

let percent = ref(0)
let duration = ref('')
let complicity = ref(0)
let amount = ref(0)
let result = ref(0)

let calculate = () => {
  let pv1 = transformedFlatData.value.price;
  let ir = percent.value / 12 / 100;
  let np = duration.value || 1;
  let pv = pv1 - (pv1 * (complicity.value / 100));

  function PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    let pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
      return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = -ir * pv * (pvif + fv) / (pvif - 1);

    if (type === 1)
      pmt /= (1 + ir);

    return pmt;
  }

  result.value = Math.floor(PMT(ir, np, pv, 0, 0) * -1);
}
</script>

<style lang="scss" scoped>
.calculator-pop-up {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, .3);
  display: flex;
  justify-content: flex-end;

  .title {
    font-size: 55px;
    @media (max-width: 1023px) {
      font-size: 34px;
    }
  }

  .calculator-content {
    overflow-y: auto;
    width: 60%;
    background: white;
    height: 100%;
    font-weight: 400;
    font-size: 34px;
    color: black;
    padding: 12vh 75px 0;
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    @media (max-width: 1023px) {
      width: 100%;
      padding: 8vh 21px 0;

    }

    .close-icon {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      transition: all .45s ease;

      &:hover {
        opacity: .5;
      }
    }

    .label {
      color: #9C9C9C;
    }

    .range-container {
      margin-top: 54px;
      width: 100%;

      .vue-slider {
        width: 100% !important;
      }

      span {
        margin-bottom: 20px;
        display: block;
      }
    }

    .item {
      margin-top: 54px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #000000;
      padding-bottom: 20px;
      width: 100%;

      .number {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .left {
          display: flex;
          align-items: center;

          span {
            margin-right: 10px;
          }
        }

        span {
          color: #9C9C9C;
          transition: all .45s ease;
          display: block;
          cursor: pointer;

          &:last-child {
            justify-self: flex-end;
          }

          &.active {
            color: black;
          }
        }
      }
    }
  }

  .calculator-select {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 55px;

    .label {
      margin-right: 10px;
      min-width: fit-content;
    }

    .select {
      position: relative;
      width: 100%;

      .select-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .title {
          font-weight: 400;
          font-size: 34px;
        }
      }

      .selected-options {
        opacity: 0;
        position: absolute;
        bottom: 0;
        background: white;
        padding: 20px;
        pointer-events: none;
        transform: translateY(100%);
        width: 100%;

        .background {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;

        }

        &.active {
          opacity: 1;
          pointer-events: auto;
        }

        .option-item {
          position: relative;
          z-index: 1;
          text-align: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .text-contents {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;

    .text {
      font-size: 24px;
    }

    .header-title {
      font-size: 64px;
      margin-top: 34px;
    }

    .title {
      font-size: 34px;
      margin-top: 24px;
    }
  }

  .input {
    width: 100%;

    input {
      font-size: 34px;
      line-height: 41px;
      height: 90px;
      border: none;
      border-bottom: 1px solid #000000;
      margin-bottom: 30px;
      width: 100%;
    }
  }

  .button {
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

</style>

<style lang="scss">
.calculator-pop-up {
  .custom-dot {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>