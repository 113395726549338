<template>
  <div class="pdf">
    <div class="main-header">
      <CitizenLogo />
      <h1 class="ttl">CITYZEN.GE</h1>
    </div>
    <div class="container">
      <div class="head"> 
        <div class="item">
          <span class="ttl">{{ $fn.tr('Apartement N') }} {{ transformedFlat.flat_number }}</span>
          <span class="ttl">{{ transformedFlat.type.title_2 }}</span>
        </div>
        <div class="item">
          <span class="num">{{ transformedFlat.type.area_m2 }} m<sup>2</sup></span>
        </div>
        <div class="item">
          <span class="label">{{ $fn.tr('Green Frame') }}</span>
          <div class="num">
            <span @click="activeCurrency = 'USD'" :class="{ active: activeCurrency === 'USD' }">$</span>
            {{ transformedFlat.price }}
            <span @click="activeCurrency = 'GEL'" :class="{ active: activeCurrency === 'GEL' }">₾</span>
          </div>
        </div>
      </div>

      <div class="details">
        <h2 class="ttl">{{ $fn.tr('Details') }}</h2>
        <div class="list">
          <p class="item" v-for="item in transformedFlat.type.roomAreas" :key="item.tipText">
            {{ item.tipText }}: {{ item.tipHover }}
            <span class="m">{{ $fn.tr('m') }}<sup>2</sup></span>
          </p>
        </div>
      </div>
      <div id="blue-print-sc" class="img-box">
        <img :src="transformedFlat.bluePrintImg" alt="">
      </div>
      <div class="info">
        <div class="inner">
          <div class="item">
            <h2 class="ttl">{{ $fn.tr('Are you interested?') }}</h2>
            <span>{{ $fn.tr('You may as well directly reach us with call') }} {{  $fn.tr('pdf_phone') }}</span>
          </div>
          <div class="item">
            <h2 class="ttl">{{ $fn.tr('Sales Office') }}</h2>
            <span>{{ $fn.tr('pdf_street') }}</span>
            <span>{{  $fn.tr('pdf_email')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, inject } from "vue";
import get from "lodash/get";
import CitizenLogo from '../UI/CitizenLogo.vue';
import { useMenuStore } from "@/store/pinia/menu";

let store = useMenuStore()
const getUSD = computed(() => {
  return parseFloat(get(store, "indx.usd.usd"));
});
let activeCurrency = ref('USD')
let about = computed(() => store.indx?.widgets?.['sales-office-contact'] || {})

const { flat, transformedFlat } = inject('flatData')

</script>

<style lang="scss" scoped>
.pdf {
  padding: 80px 60px;
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ttl {
    font-size: 26px;
    font-weight: 400;
    line-height: normal;
  }

  svg {
    width: 170px;
    height: auto;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}

.head {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #F5F5F5;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding-right: 20px;
    margin-right: 20px;
  }

  .item:nth-child(2) {
    flex: none;
  }

  .item:last-child {
    flex: none;
    padding-right: 0;
    margin-right: 0;
  }

  .item:after {
    content: '';
    width: 1px;
    height: 50px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .item:last-child:after {
    display: none;
  }

  .ttl {
    display: block;
    font-size: 18px;
  }

  .ttl:nth-child(2) {
    margin-top: 5px;
  }

  .num {
    font-size: 25px;
    font-weight: bold;

    span{
      display: none;
    }
    span.active{
      display: inline-block;
    }
  }

  .label {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.details {
  margin-top: 30px;

  .ttl {
    font-size: 18px;
    color: #9C9C9C;
  }

  .list {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    width: 25%;
    padding-right: 10px;
    margin-bottom: 6px;
    font-size: 10px;
  }

  .item:nth-child(4n) {
    padding-right: 0px;
  }
}

.img-box {
  margin-top: 30px;
  width: 100%;
  min-height: 400px;
  background: #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;
    object-fit: cover;
  }
}

.info {
  margin-top: auto;

  .inner {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #F5F5F5;
  }

  .ttl {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
  }

  span:nth-child(3) {
    margin-top: 4px;
  }

  .item {
    display: flex;
    flex-direction: column;
  }

  .item:first-child {
    margin-bottom: 16px;
  }
}
</style>